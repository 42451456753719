import React, { Fragment } from 'react';
// import './styles.css';
import { useTranslation } from 'react-i18next';
import { grey } from '@mui/material/colors';
import { toolTip } from './styles';
import { Tooltip } from '@mui/material';
import { line, curveMonotoneX as curveLine } from 'd3-shape';
import { ResponsiveBar } from '@nivo/bar';
import _ from 'lodash';

const today = new Date();
const barColor = '#5ec297'; // #36b37e
const lineColor = grey[400]; // 'rgba(33, 150, 243, 0.5)';
const dotColor = grey[700]; // '#0969FA';

const toolTipElement = (props, ...PointTooltipProps) => {
  return (
    <div
      className={'toolTip'}
      sx={{ ...toolTip, fontSize: 12, color: 'red', background: 'red' }}
      style={{
        color: 'white',
        backgroundColor: grey[700],
        padding: '5px 9px',
        fontFamily: 'Lato',
        fontSize: 12,
        fontWeight: 'bold',
        boxShadow: '0px 5px 15px rgba(0,0,0,0.1)',
        // border: `2px solid ${bdtheme.palette.text.hint}`,
        borderRadius: 4,
      }}
    >
      {props && props.data && props.value}
    </div>
  );
};

const Line = ({ bars, xScale, yScale }) => {
  const lineGenerator = line()
    .curve(curveLine)
    .x((bar) => xScale(bar.data.indexValue) + bar.width / 2)
    .y((bar) => yScale(bar.data.data.v1));
  return (
    <Fragment>
      <path
        d={lineGenerator(bars)}
        fill='none'
        stroke={lineColor}
        strokeWidth={2}
        style={{ pointerEvents: 'none' }}
      />
      {bars &&
        bars.map((bar) => (
          <Tooltip
            title={bar.data.data.v1 > 0.001 ? bar.data.data.v1 : 0}
            key={bar.key}
          >
            <circle
              key={bar.key}
              cx={xScale(bar.data.indexValue) + bar.width / 2}
              cy={bar.data.data.v1 && yScale(bar.data.data.v1)}
              r={4}
              fill={dotColor}
              stroke={dotColor}
              // onMouseOver={console.log()}
              // style={{ pointerEvents: 'none' }}
            />
          </Tooltip>
        ))}
    </Fragment>
  );
};

const ComboChart = (props) => {
  const { t } = useTranslation();
  // `v` is used for bars/deals
  // `v1` is used for line/leads
  const [data, setData] = React.useState(
    Array.from(Array(12), (_, i) => {
      return { x: t(`months.${i}`), v: 0, v1: 0 };
    })
  );

  const [maxval, setMaxval] = React.useState(100);
  const { dealsChartData, leadsChartData } = props;

  React.useEffect(() => {
    let comboData = [...data];
    if (
      leadsChartData &&
      leadsChartData.length > 0 &&
      dealsChartData &&
      dealsChartData.length > 0
    ) {
      comboData.forEach((item, i) => {
        item.v1 =
          leadsChartData[i].value > 0
            ? leadsChartData[i].value
            : leadsChartData[i].value + 0.001;
        item.v = dealsChartData[i].value;
        return item;
      });

      const maxLeads =
        leadsChartData && leadsChartData.length > 0
          ? _.maxBy(leadsChartData, 'value').value
          : 100;
      const maxDeals =
        dealsChartData && dealsChartData.length > 0
          ? _.maxBy(dealsChartData, 'value').value
          : 100;
      const maxGrid = _.max([maxLeads, maxDeals]);
      setMaxval(
        maxGrid <= 4
          ? 4
          : maxGrid % 4 > 0
          ? maxGrid + (4 - (maxGrid % 4))
          : maxGrid
      );

      setData(comboData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealsChartData, leadsChartData]);

  return leadsChartData &&
    dealsChartData.length > 0 &&
    dealsChartData &&
    leadsChartData.length > 0 ? (
    <ResponsiveBar
      theme={props.theme}
      data={data}
      keys={['v']}
      maxValue={maxval}
      padding={0.6}
      margin={{
        top: 58,
        right: 4,
        bottom: 24,
        left: 28,
      }}
      indexBy='x'
      enableLabel={false}
      colors={[barColor]}
      borderRadius={2}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      axisLeft={{
        tickValues: [0, maxval / 4, maxval / 2, (maxval / 4) * 3, maxval],
        tickPadding: 5,
        tickSize: 0,
      }}
      gridYValues={[0, maxval / 4, maxval / 2, (maxval / 4) * 3, maxval]}
      layers={['grid', 'axes', 'bars', Line, 'markers', 'legends']}
      tooltip={toolTipElement}
      legends={[
        {
          anchor: 'top-right',
          dataFrom: 'keys',
          data: [
            {
              id: 1,
              label: `Leads (${_.sumBy(leadsChartData, 'value')})`,
              color: dotColor,
            },
            {
              id: 2,
              label: `Deals (${_.sumBy(dealsChartData, 'value')})`,
              color: barColor,
            },
          ],
          direction: 'column',
          itemHeight: 20,
          itemWidth: 85,
          toggleSerie: false,
          // translateX: -10,
          translateY: -60,
        },
      ]}
    />
  ) : (
    <></>
  );
};

export default ComboChart;
