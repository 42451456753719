import { createTheme } from '@mui/material/styles';
import grey from '@mui/material/colors/grey';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0969FA',
      light: '#4f95ff',
      dark: '#154999',
      contrastText: '#fff',
    },
    secondary: {
      main: '#36b37e',
      light: '#5ec297',
      dark: '#206B4B',
    },
    text: {
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
  },
  typography: {
    fontFamily: 'Lato',
  },
  shape: {},
  mixins: {
    toolbar: {
      minHeight: 64,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 56,
      },
      '@media (min-width:768px)': {
        minHeight: 72,
      },
    },
  },

  custom: {
    boxshadow: {
      boxShadow: '0px 0px 0px 1px #E0E0E0',
    },
  },
});

theme.components = {
  // Name of the component
  MuiButtonBase: {
    defaultProps: {
      // The props to change the default for.
      // disableRipple: true, // No more ripple!
    },
  },
  MuiButton: {
    defaultProps: {
      disableTouchRipple: true,
    },
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        textTransform: 'none',
        borderRadius: '20px',
      },
      containedPrimary: {
        '&.Mui-disabled': {
          pointerEvents: 'auto',
        },
        '&:hover': {
          // backgroundColor: theme.palette.primary.dark,
        },
      },
      outlined: {
        border: '1px solid #E0E0E0',
        '&:hover': {
          backgroundColor: grey[50],
        },
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      disableTouchRipple: true,
    },
    styleOverrides: {
      root: {
        '&:hover': {},
      },
      colorPrimary: {
        '&:hover': {
          backgroundColor: theme.palette.primary.shadow,
        },
      },
      colorSecondary: {
        '&:hover': {
          backgroundColor: theme.palette.secondary.shadow,
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      InputLabelProps: {
        shrink: true,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      adornedEnd: {
        '&:hover svg:not(.MuiChip-deleteIcon)': {
          fill: theme.palette.primary.main,
        },

        '&.Mui-focused svg:not(.MuiChip-deleteIcon)': {
          fill: theme.palette.primary.main,
        },
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      disableTouchRipple: true,
    },
    styleOverrides: {
      colorSecondary: {
        '&$checked': {
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.primary.shadow,
          },
        },
        '&.Mui-checked': {
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.primary.shadow,
          },
        },
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      // colorSecondary
      root: {
        '&$checked': {
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.primary.shadow,
          },
        },
        '&.Mui-checked': {
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.primary.shadow,
          },
        },
      },
      colorPrimary: {
        '&:hover': {
          backgroundColor: theme.palette.primary.shadow,
        },
        '&$checked': {
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.primary.shadow,
          },
        },
        '&.Mui-checked': {
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.primary.shadow,
          },
        },
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      //disableRipple: true,
    },
    styleOverrides: {
      root: {
        '&.Mui-focused svg': {
          fill: theme.palette.primary.main,
        },
      },
      select: {
        '&.Mui-select': {
          backgroundColor: 'transparent',
        },
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&$selected, &$selected:hover': {
          backgroundColor: grey[50],
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      stickyHeader: {
        backgroundColor: 'transparent',
      },
    },
  },

  MuiTab: {
    styleOverrides: {
      root: {
        '&$selected': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  MuiTooltip: {
    defaultProps: {
      background: grey[700],
      opacity: 1,
    },

    styleOverrides: {
      popper: {
        '& *': {
          fontSize: 14,
        },
      },
      tooltip: {
        backgroundColor: grey[700],
        opacity: 1,
        fontSize: 14,
      },
    },
  },
  MuiSnackbar: {
    styleOverrides: {
      anchorOriginBottomLeft: {
        zIndex: 9999,
        bottom: '64px !important',
        left: '64px !important',
        justifyContent: 'flex-start',
        [theme.breakpoints.up('sm')]: {
          bottom: '64px !important',
          left: '64px !important',
          justifyContent: 'flex-start',
        },
        [theme.breakpoints.down('md')]: {
          justifyContent: 'center',
          bottom: '8px !important',
          left: '8px !important',
          right: '8px',
        },
      },
    },
  },
  MuiSnackbarContent: {
    styleOverrides: {
      root: {
        minWidth: '0px !important',
        maxWidth: '600px !important',
        alignItems: 'flex-start',
        [theme.breakpoints.down('md')]: {
          maxWidth: 'fit-content !important',
        },
      },
      message: {
        flex: '1',
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        '&$selected': {
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      //MuiBackdrop-root-MuiDialog-backdrop
      //MuiBackdrop-invisible
      root: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
      },
      invisible: {
        opacity: 0 + ' !important',
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      actions: {
        color: theme.palette.primary.main,
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: 24,
        '& > * ': {
          fontWeight: 'bold',
        },
        fontWeight: 'bold',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
      },
    },
  },
};
