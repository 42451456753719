/* MAIN COMPONENTS HAVE 24PX PADDING HORIZONTALY (16PX < LG) */
import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ScLogo } from '../assets/sc_logo_blue.svg';
import { UserContext } from '../contexts/UserContext';
import Loader from '../uiComponents/Loader';
import axios from 'axios';
import i18n from 'i18next';
import { useIsMounted } from '../utils/customHooks';
import {
  AppBar,
  Toolbar,
  Drawer,
  Divider,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tabs,
  Tab,
  Typography,
} from '@mui/material';
import { LoginContext } from '../contexts/LoginContext';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import DescriptionIcon from '@mui/icons-material/Description';
import BusinessIcon from '@mui/icons-material/Business';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { MenuItemss, UserItemss } from './MenuItems';
import { useMediaQuery } from 'react-responsive';
import ProfileMenu from '../uiComponents/ProfileMenu';
import CustomDialog from '../uiComponents/CustomDialog';

const NavBar = (props) => {
  const isMounted = useIsMounted();
  const { setLocale } = useContext(LoginContext);
  const classes = useStyles();
  let location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleChangeTab = (event, newValue) => {
    props.setTabValue(newValue);
  };
  const [userContext, setUserContext] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [accountMode, setAccountMode] = useState(false);
  const [projectMode, setProjectMode] = useState(false);
  const [applicationMode, setApplicationMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(false);
  const [backProjectId, setBackProjectId] = useState(false);
  const [backAccountId, setBackAccountId] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const MenuItems = MenuItemss();
  const UserItems = UserItemss();

  const logoutHandler = () => {
    setLoading(true);
    let apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;
    axios({
      method: 'post',
      url: apiEndPoint + '/v1/partner/auth/logout',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((res) => {
        localStorage.setItem('partner-token', null);
        localStorage.setItem('partner-user', null);
        localStorage.setItem('partner-logout', Date.now());
        setUserContext((oldValues) => {
          return {
            ...oldValues,
            user: null,
            token: null,
          };
        });
        setLoading(false);
        history.push('/login');
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{ display: 'content' }}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectLocale = React.useCallback((locale) => {
    if (isMounted.current) {
      setLocale(i18n.languages[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname === '/app/dashboard') {
      setTitle(t('dashboard.mainMenu.navitem1'));
      setAccountMode(false);
      setProjectMode(false);
      setApplicationMode(false);
      setEditMode(false);
    } else if (location.pathname === '/app/accounts') {
      setTitle(t('dashboard.mainMenu.navitem2'));
      setAccountMode(false);
      setProjectMode(false);
      setApplicationMode(false);
      setEditMode(false);
    } else if (location.pathname === '/app/deals') {
      setTitle(t('dashboard.mainMenu.navitem3'));
      setAccountMode(false);
      setProjectMode(false);
      setApplicationMode(false);
      setEditMode(false);
    } else if (location.pathname === '/app/applications') {
      setTitle(t('dashboard.mainMenu.navitem4'));
      setAccountMode(false);
      setProjectMode(false);
      setApplicationMode(false);
      setEditMode(false);
    } else if (location.pathname === '/app/account') {
      setTitle(t('dashboard.my_account'));
      setAccountMode(false);
      setProjectMode(false);
      setApplicationMode(false);
      setEditMode(false);
    } else if (location.pathname.includes('/edit')) {
      let name;
      if (location.state) {
        name = location.state.label;
      } else {
        name = t('dashboard.menu.navitem6');
      }
      let pathname = location.pathname;
      let url = pathname.slice(0, -5);
      let id = url.substring(url.lastIndexOf('/') + 1);
      setBackProjectId(id);
      setTitle(name);
      setAccountMode(false);
      setProjectMode(true);
      setApplicationMode(false);
      setEditMode(true);
    } else if (location.pathname.includes('/app/accounts/')) {
      if (location.state) {
        let name = location.state.name;
        setTitle(name);
      } else {
        const apiEndPoint =
          process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_API_ENDPOINT
            : process.env.REACT_APP_API_ENDPOINT_PROD;
        let id = location.pathname.substring(
          location.pathname.lastIndexOf('/') + 1
        );
        axios({
          method: 'get',
          url: apiEndPoint + '/v1/partner/leads/getLead',
          withCredentials: true,
          params: { id: id },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userContext.token}`,
          },
        }).then((res) => {
          setTitle(res.data.account.name);
        });
      }
      setAccountMode(true);
      setProjectMode(false);
      setApplicationMode(false);
      setEditMode(false);
    } else if (location.pathname.includes('/app/projects/')) {
      if (location.state) {
        let name = location.state.name;
        let accountId = location.state.accountId;
        setTitle(name);
        setBackAccountId(accountId);
      } else {
        const apiEndPoint =
          process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_API_ENDPOINT
            : process.env.REACT_APP_API_ENDPOINT_PROD;
        let id = location.pathname.substring(
          location.pathname.lastIndexOf('/') + 1
        );
        let requestBody = {
          projectId: id,
        };
        axios({
          method: 'post',
          url: apiEndPoint + '/v1/partner/project/getProjectName',
          withCredentials: true,
          data: requestBody,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userContext.token}`,
          },
        }).then((res) => {
          setTitle(res.data.name);
          setBackAccountId(res.data.accountId);
        });
      }
      setAccountMode(false);
      setProjectMode(true);
      setApplicationMode(false);
      setEditMode(false);
    } else if (location.pathname.includes('/app/applications/')) {
      if (location.state) {
        let name = location.state.name;
        setTitle(name);
      } else {
        const apiEndPoint =
          process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_API_ENDPOINT
            : process.env.REACT_APP_API_ENDPOINT_PROD;
        let id = location.pathname.substring(
          location.pathname.lastIndexOf('/') + 1
        );
        axios({
          method: 'get',
          url: apiEndPoint + '/v1/partner/application/getApplication',
          withCredentials: true,
          params: { id: id },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userContext.token}`,
          },
        }).then((res) => {
          setTitle(res.data.subsidyId.name);
        });
      }
      setAccountMode(false);
      setProjectMode(false);
      setApplicationMode(true);
      setEditMode(false);
    } else {
      setTitle('');
      setAccountMode(false);
      setProjectMode(false);
      setApplicationMode(false);
      setEditMode(false);
    }
    props.setTabValue(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setLoading(true);

    let apiEndPoint =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT_PROD;

    axios({
      method: 'get',
      url: apiEndPoint + '/v1/partner/auth/getUser',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userContext.token}`,
      },
    })
      .then((res) => {
        if (isMounted.current) {
          setUserContext((oldValues) => {
            return {
              ...oldValues,
              user: res.data,
            };
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          console.log(error);
          setLoading(false);
        }
      });

    selectLocale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <AppBar position='fixed' color='default' className={classes.appBar}>
        <Toolbar
          className={classes.toolbarContent}
          style={!title ? { paddingLeft: 0 } : null}
        >
          {isLargeScreen ? (
            !editMode ? (
              <Typography
                variant='h6'
                noWrap
                style={
                  !projectMode || !applicationMode || !accountMode
                    ? { paddingLeft: 24, paddingRight: 24 }
                    : { paddingLeft: 24, paddingRight: 24 }
                }
              >
                {projectMode && (
                  <IconButton
                    style={{ marginRight: 24 }}
                    color='primary'
                    size='small'
                    component={Link}
                    to={{ pathname: `/app/accounts/${backAccountId}` }}
                  >
                    <ArrowBackIcon fontSize='large' />
                  </IconButton>
                )}
                {applicationMode && (
                  <IconButton
                    style={{ marginRight: 24 }}
                    color='primary'
                    size='small'
                    component={Link}
                    to='/app/applications'
                  >
                    <ArrowBackIcon fontSize='large' />
                  </IconButton>
                )}
                {accountMode && (
                  <IconButton
                    style={{ marginRight: 24 }}
                    color='primary'
                    size='small'
                    component={Link}
                    to='/app/accounts'
                  >
                    <ArrowBackIcon fontSize='large' />
                  </IconButton>
                )}
                {title}
              </Typography>
            ) : (
              <>
                <Typography
                  variant='h6'
                  noWrap
                  style={{ paddingLeft: 24, paddingRight: 24 }}
                >
                  {projectMode && (
                    <IconButton
                      style={{ marginRight: 24 }}
                      color='primary'
                      size='small'
                      component={Link}
                      to={{ pathname: `/app/projects/${backProjectId}` }}
                    >
                      <ArrowBackIcon fontSize='large' />
                    </IconButton>
                  )}
                  {title}
                </Typography>
              </>
            )
          ) : (
            <>
              {title ? (
                <>
                  <Typography
                    variant='h6'
                    noWrap
                    style={
                      (!projectMode && !editMode) ||
                      !applicationMode ||
                      !accountMode
                        ? { paddingLeft: 24 }
                        : { paddingLeft: 16, paddingRight: 16 }
                    }
                  >
                    {projectMode && !editMode && (
                      <IconButton
                        color='primary'
                        size='small'
                        style={{ marginRight: 16 }}
                        component={Link}
                        to={{ pathname: `/app/accounts/${backAccountId}` }}
                      >
                        <ArrowBackIcon fontSize='medium' />
                      </IconButton>
                    )}
                    {applicationMode && (
                      <IconButton
                        color='primary'
                        size='small'
                        style={{ marginRight: 16 }}
                        component={Link}
                        to='/app/applications'
                      >
                        <ArrowBackIcon fontSize='medium' />
                      </IconButton>
                    )}
                    {accountMode && (
                      <IconButton
                        color='primary'
                        size='small'
                        style={{ marginRight: 16 }}
                        component={Link}
                        to='/app/accounts'
                      >
                        <ArrowBackIcon fontSize='medium' />
                      </IconButton>
                    )}
                    {projectMode && editMode && (
                      <IconButton
                        color='primary'
                        size='small'
                        style={{ marginRight: 16 }}
                        component={Link}
                        to={{
                          pathname: `/app/projects/${backProjectId}`,
                        }}
                      >
                        <ArrowBackIcon fontSize='medium' />
                      </IconButton>
                    )}
                    {title}
                  </Typography>
                  {!projectMode &&
                    !editMode &&
                    !applicationMode &&
                    !accountMode && (
                      <IconButton
                        color='inherit'
                        aria-label='open drawer'
                        onClick={handleDrawerOpen}
                        edge='end'
                        className={classes.menuButton}
                        size='large'
                      >
                        <MenuIcon />
                      </IconButton>
                    )}
                </>
              ) : (
                <>
                  <ScLogo
                    className={classes.imageLogo}
                    width='168'
                    height='52'
                  />
                  <IconButton
                    color='inherit'
                    aria-label='open drawer'
                    onClick={handleDrawerOpen}
                    edge='end'
                    className={classes.menuButton}
                    size='large'
                  >
                    <MenuIcon />
                  </IconButton>
                </>
              )}
            </>
          )}
          {isLargeScreen && (
            <ProfileMenu
              classUser={classes.user}
              handleClick={handleClick}
              anchorEl={anchorEl}
              handleClose={handleClose}
              handlelogout={logoutHandler}
              user={userContext.user}
            />
          )}
        </Toolbar>
        {!editMode && projectMode && (
          <>
            <Tabs
              className={classes.projectTabs}
              value={props.tabValue}
              onChange={handleChangeTab}
              aria-label='simple tabs example'
              indicatorColor='primary'
            >
              <Tab label={t('projectX.tab1')} {...a11yProps(0)} />
              <Tab label={t('projectX.tab2')} {...a11yProps(1)} />
              <Tab label={t('projectX.tab3')} {...a11yProps(2)} />
            </Tabs>
          </>
        )}
        {applicationMode && (
          <>
            <Tabs
              className={classes.projectTabs}
              value={props.tabValue}
              onChange={handleChangeTab}
              aria-label='simple tabs example'
              indicatorColor='primary'
            >
              <Tab label={t('projectX.tab1')} {...a11yProps(0)} />
              <Tab label={t('projectX.tab3')} {...a11yProps(1)} />
            </Tabs>
          </>
        )}
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant={isLargeScreen ? 'persistent' : 'temporary'}
        classes={{
          paper: clsx(
            classes.drawer,
            classes.drawerPaper,
            !open && classes.drawerPaperClose
          ),
        }}
        open={isLargeScreen ? true : open}
        anchor={isLargeScreen ? 'left' : 'left'}
        ModalProps={{
          onBackdropClick: handleDrawerClose,
          onEscapeKeyDown: handleDrawerClose,
        }}
      >
        {isLargeScreen ? (
          <div className={classes.toolbar}>
            <ScLogo className={classes.imageLogo} width='168' height='52' />
          </div>
        ) : (
          <div className={classes.drawerHeader}>
            <ScLogo className={classes.imageLogo} width='168' height='52' />
            <IconButton
              onClick={handleDrawerClose}
              color='inherit'
              size='large'
            >
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
        )}
        <List className={classes.menuList}>
          {MenuItems.map((item, index) => (
            <ListItem
              key={item.title}
              component={Link}
              to={item.href}
              onClick={() => {
                handleDrawerClose();
              }}
              selected={location.pathname.startsWith(item.href)}
            >
              <ListItemIcon>
                {index === 0 && <DashboardIcon />}
                {index === 1 && <PersonAddIcon />}
                {/*index === 2 && <BusinessIcon />*/}
                {index === 2 && <DescriptionIcon />}
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </List>
        {!isLargeScreen && (
          <>
            <Divider />
            <List className={classes.menuList}>
              {UserItems.map((item, index) => (
                <ListItem
                  key={item.title}
                  component={item.id !== 'logout' ? Link : 'div'}
                  to={item.href}
                  onClick={() => {
                    index === 2 ? setOpenDialog(true) : handleDrawerClose();
                  }}
                  selected={location.pathname.startsWith(item.href)}
                >
                  <ListItemIcon>
                    {index === 0 && <AccountCircleIcon />}
                    {index === 1 && (
                      <LogoutIcon
                        style={{ fill: theme.palette.text.secondary }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Drawer>

      <CustomDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        handleConfirm={logoutHandler}
        title={t('logout')}
        text={t('dialog.text3')}
        cancel={t('buttons.close')}
        confirm={t('buttons.continue')}
      />
    </>
  );
};

export default NavBar;
