/* MAIN COMPONENTS HAVE 24PX PADDING HORIZONTAL (16PX < LG) */
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as ScLogo } from '../assets/sc_logo_blue.svg';
import { AppBar, Toolbar, Grid, Typography } from '@mui/material';
import { useMediaQuery } from 'react-responsive';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  header: {
    height: 72,
    [theme.breakpoints.down('768')]: {
      height: 64,
    },
  },
  appbar: {
    zIndex: '5',
    background: '#ffffff',
    height: 72,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.08) !important',
    [theme.breakpoints.down('768')]: {
      height: 64,
    },
  },
  toolbar: {
    justifyContent: 'space-between',
    height: '100%',
    '&.MuiToolbar-gutters': {
      paddingLeft: 24,
      paddingRight: 24,
      [theme.breakpoints.down('768')]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('1280')]: {
      justifyContent: 'space-between',
    },
    '& button': {
      marginLeft: 48,
      [theme.breakpoints.down('768')]: {
        marginLeft: 8,
      },
      border: '1px solid #E0E0E0',
      '&:hover': {
        border: '1px solid #E0E0E0',
        backgroundColor: '#FAFAFA',
      },
    },
  },
  main: {
    minHeight: 'calc(100% - 72px)',
    [theme.breakpoints.down('768')]: {
      minHeight: 'calc(100% - 64px)',
      overflowY: 'auto',
    },
  },
}));

const BaseLayout = (props) => {
  const classes = useStyles();
  const isLarge = useMediaQuery({ query: '(min-width: 1280px)' });
  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.header}>
          <AppBar
            enableColorOnDark
            // color={props.color ? props.color : 'default'}
            className={classes.appbar}
          >
            <Toolbar className={classes.toolbar}>
              {props.title ? (
                <>
                  <Typography variant='h6' style={{ fontWeight: 700 }}>
                    {props.title}
                  </Typography>
                </>
              ) : (
                <div>
                  <ScLogo width='168' height='52' />
                </div>
              )}

              {props.user && <div className={classes.user}>{props.user}</div>}
              {props.close && (
                <div className={classes.button}>{props.close}</div>
              )}
            </Toolbar>
          </AppBar>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.main}
          lg={props.sidebar && isLarge ? 9 : 12}
        >
          {props.main}
        </Grid>
        {props.sidebar && isLarge && (
          <Grid item xs={12} lg={3} className={classes.sidebar}>
            {props.sidebar}
          </Grid>
        )}
        {props.footer && (
          <Grid item xs={12} className={classes.footer}>
            {props.footer}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default BaseLayout;
