import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useTranslation } from 'react-i18next';
import { ListItemText, ListItemIcon } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SendIcon from '@mui/icons-material/Send';
import DropzoneButton from './uploadComponents/DropzoneButton';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function AddLeadButton(props) {
  const {
    setOpenNewLeadDialog,
    setOpenMapLeadDialog,
    setOpenInvitationDialog,
  } = props;
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery('(max-width:767px)');
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant='contained'
        ref={anchorRef}
        aria-label='split button'
        disableElevation
      >
        <Button
          onClick={() => {
            setOpenNewLeadDialog(true);
          }}
        >
          {t('leads.add_new')}
        </Button>
        <Button
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select merge strategy'
          aria-haspopup='menu'
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 2,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem>
                  <MenuItem
                    key={'add_lead'}
                    onClick={() => {
                      setOpenNewLeadDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <AddIcon color='primary' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>{t('leads.menu_add_new')}</ListItemText>
                  </MenuItem>
                  {!isSmallScreen && (
                    <MenuItem sx={{ padding: 0 }}>
                      <ButtonGroup
                        variant='text'
                        aria-label='text button group'
                      >
                        <Button
                          color='primary'
                          variant={'text'}
                          startIcon={
                            <UploadFileIcon sx={{ mr: '-4px', ml: 1.25 }} />
                          }
                          sx={{
                            width: 1,
                            minWidth: 'fit-content',
                            padding: 0,
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                            '& > span': {
                              ml: 0.5,
                            },
                          }}
                        >
                          <DropzoneButton
                            textColor='rgba(0,0,0,0.87)'
                            fontSize='16px'
                            minHeight='36px'
                            padding={true}
                            inputContent={t('leads.upload_file')}
                            handleClose={handleClose}
                            setOpenMapLeadDialog={setOpenMapLeadDialog}
                            error1={t('leads.field_mapping.error1')}
                            error2={t('leads.field_mapping.error7')}
                            error3={t('leads.field_mapping.no_data')}
                          />
                        </Button>
                        <Button
                          sx={{
                            minWidth: 'fit-content !important',
                            px: 2,
                            color: 'inherit',
                            fontSize: 16,
                            '&:hover': {
                              color: '#0969FA',
                            },
                          }}
                        >
                          <a
                            href={
                              process.env.PUBLIC_URL + '/csv/sample_lead.csv'
                            }
                            download
                          >
                            {t('leads.download_sample')}
                          </a>
                        </Button>
                      </ButtonGroup>
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      setOpenInvitationDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <SendIcon color='primary' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>{t('leads.menu_send')}</ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
