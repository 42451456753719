export const dropzone = {
  overflow: 'hidden',
  minHeight: 128,
  maxHeight: 450,
  maxWidth: 450,
  borderWidth: 1,
};

export const dropzoneLabel = {
  fontSize: '14px',
  fontWeight: 'normal',
  color: 'rgba(0, 0, 0, 0.54)',
  letterSpacing: '0.15px',
  fontFamily: 'Lato',
  lineHeight: '143%',
  padding: '44px 92px',
  textAlign: 'center',
};

export const dropzoneButton = {
  overflow: 'hidden',
  minHeight: 36,
  maxHeight: 24, // 40,
  minWidth: 152,
  maxWidth: 152,
  borderWidth: 0,
  marginLeft: '-36px',
  // backgroundColor: (theme) => theme.palette.primary.main,
};

export const dropzoneLabelButton = {
  fontSize: '14px',
  fontWeight: 'normal',
  minHeight: 25,
  maxHeight: 40,
  minWidth: 148,
  maxWidth: 148,
  // color: 'rgba(0, 0, 0, 0.54)',
  letterSpacing: '0.15px',
  fontFamily: 'Lato',
  lineHeight: '143%',
  // color: '#ffffff',
  paddingLeft: '12px',
  // padding: '4px 4px',
  textAlign: 'center',
  borderRadius: 20,
  backgroundColor: 'none', // '#0969FA', // (theme) => theme.palette.primary.main,
};

export const preview = {
  visibility: 'hidden',
  padding: '42px 24px',
  borderBottom: 'none',
  '& progress': {
    color: 'green',
  },
};
