import React from 'react';
import { Box, Fade, Backdrop, CircularProgress } from '@mui/material';

const loader = {
  bottom: 0,
  top: 0,
  position: 'fixed',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  zIndex: 9990,
};

const spinner = {
  transitionDelay: '100ms',
  color: '#0969FA',
};

const Loader = (props) => {
  return (
    <Box sx={loader}>
      <Backdrop
        open={props.loading}
        sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
      >
        <Fade in={props.loading} unmountOnExit>
          <CircularProgress disableShrink sx={spinner} />
        </Fade>
      </Backdrop>
    </Box>
  );
};
export default Loader;
